<template>  

    <b-card title="Upload Courier Charges">
        <div class="demo-inline-spacing">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="primary"
                
            >
                Upload File
            </b-button>
                
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                v-on:click="sendForm"
                :disabled="submitBtnState == false"
                >
                Submit
            </b-button>
        </div>
    
        <br>
        

        <!-- basic modal -->
        <b-modal
        id="modal-1"
        title="Upload File"
        ok-only
        ok-title="Upload"
        @ok="okBtn"
        >
        
            <b-form-file
            multiple
            :file-name-formatter="formatNames"
            @change="read"
            />
        
        </b-modal>

        <div v-if="showTable == true">
            <!-- table -->
            <vue-good-table
            :line-numbers="true"
            ref="courier_receivables_aging"
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            
            >
        
        
        
            <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                v-on:click="reconciled"
                >
                Mark as Reconciled
            </b-button> -->
    
            <template
                slot="table-row"
                slot-scope="props"
            >

                <!-- Column: Name -->
                <!-- <span
                v-if="props.column.field === 'fullNamae'"
                class="text-nowrap"
                >
                <b-avatar
                    :src="props.row.avatar"
                    class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span> -->

                <!-- Column: Status -->
                <span v-if="props.column.field === 'message'">
                    <div v-if="props.row.message == 'Clear'">
                        <b-badge variant="success">
                            {{ props.row.message }}
                        </b-badge>
                    </div>
                    <div v-else>
                        <b-badge variant="danger">
                            {{ props.row.message }}
                        </b-badge>
                    </div>
                </span>
                

                <!-- Column: Action -->
                <!-- <span v-else-if="props.column.field === 'action'">
                <span>
                    <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    >
                    <template v-slot:button-content>
                        <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                        />
                    </template>
                    <b-dropdown-item>
                        <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                        />
                        <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                        />
                        <span>Delete</span>
                    </b-dropdown-item>
                    </b-dropdown>
                </span>
                </span> -->

                <!-- Column: Common -->
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            
            </vue-good-table>
        </div>

    


    
    
    </b-card>

</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

import VuePapaParse from 'papaparse'

export default {
  components: {
    VueGoodTable,
    BCard,
    BFormFile,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,
    VuePapaParse,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      allData:null,
      file:'',
      content:[],
      submitBtnState:false,
      parsed:false,
      showTable:false,
      log: [],
      pageLength: 10,
      totalRecords:0,
      dir: false,
      pages: ['10','20','50','100'],
      columns: [
        {
          label: 'Message',
          field: 'message',
          sortable: false,
        },
        {
          label: 'Courier',
          field: 'courier',
          sortable: false
        },
        {
          label: 'Tracking ID',
          field: 'tracking_id',
          sortable: false
        },
        {
          label: 'Weight',
          field: 'weight',
          formatFn:this.formatCharges,
          sortable: false
        },
        {
          label: 'Weight Charges',
          field: 'weight_charges',
          formatFn:this.formatCharges,
          sortable: false
        },
        {
          label: 'Fuel Surcharge',
          field: 'fuel_surcharge',
          formatFn:this.formatCharges,
          sortable: false
        },
        {
          label: 'Cash Handling Charges',
          field: 'cash_handling_charges',
          formatFn:this.formatCharges,
          sortable: false
        },
        {
          label: 'Adjustment Charges',
          field: 'adjustment_charges',
          formatFn:this.formatCharges,
          sortable: false
        },
        {
          label: 'Return Charges',
          field: 'return_charges',
          formatFn:this.formatCharges,
          sortable: false
        },
        {
          label: 'Sales Tax',
          field: 'sales_tax',
          formatFn:this.formatCharges,
          sortable: false
        },
        {
          label: 'Total Actual Courier Charges',
          field: 'total_actual_courier_charges',
          formatFn:this.formatCharges,
          sortable: false
        },
      ],
      rows: [],

      
    }
  },
  computed: {
    

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  
  methods: {
    formatCharges:function(data){
      if(data != null){
        return data.toFixed(2);
      }
    },

    formatNames: function(files) {
      if (files.length === 1) {
        return files[0].name
      }
      return `${files.length} files selected`
    },

    okBtn: function(){
        this.parseFile();
    },

    read: function(ev) {
        // this.showTable = true;
        this.file = ev.target.files[0];
        // this.parseFile();
    },

    parseFile: function(){
        var fl = this.file;
        VuePapaParse.parse( this.file, {
            header: true,
            skipEmptyLines: true,
            complete: function( results,fl ){
                this.content = results;
                this.parsed = true;
                this.sendDataOnly(results);
                // this.rows = results.data;
                
            }.bind(this)
        } );
    },

    sendDataOnly: function(results){
        this.$http.post('check-upload-courier-charges', {
            results:results.data
            }
            ).then(res => {
                this.rows = res.data.data;
                if(res.data.error == 1){
                    this.submitBtnState = false;     
                } else{
                    this.submitBtnState = true;
                }
        });
        this.showTable = true;
        
    },

    sendForm: function(){
        var formData = new FormData();
        formData.append('file', this.file);
        formData.append('data', this.content);
        this.$http.post('upload-courier-charges', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            this.showTable = false;
            this.submitBtnState = false;    
        })
    }

    
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>